import { Box, Container, Stack } from "@mui/material";
import useDeviceDetect from "../../../lib/detector";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  if (isMobile()) {
    return (
      <div className="m-about-page">
        <div className="m-container">
          <Stack className="about-top">
            <Box>
              <strong>{t("About us")}</strong>
              <p>
                {t(
                  "Founded in 2020, MX has been a leader in the car shipping industry, specializing in transporting vehicles from Korea to international destinations. Our team of experts ensures a smooth and hassle-free shipping experience for our clients"
                )}
                .
                <br />
                <br />
                {t(
                  "We offer a comprehensive range of services, including door-to-door shipping, customs clearance, and vehicle inspection. Our advanced logistics network and partnerships with leading carriers enable us to deliver top-notch service every time"
                )}
                .
                <br />
                <br />
                {t(
                  "Customer satisfaction is at the heart of everything we do. We are dedicated to continuous improvement and innovation, ensuring that we meet the evolving needs of our clients"
                )}
                .
              </p>
            </Box>
            <img src="/assets/images/about-img.png" alt="" />
          </Stack>
          <Stack className="about-center">
            <Box>
              <img src="/assets/images/about-center.png" alt="" />
            </Box>
            <Box className="right">
              <strong>{t("Why choose us")}</strong>
              <p>
                <b>{t("Experience")}:</b>{" "}
                {t("Over 4 years of industry experience")}.
              </p>
              <p>
                <b>{t("Global Network")}:</b>{" "}
                {t("Extensive network of partners and carriers")}.
              </p>
              <p>
                <b>{t("Customer Service")}:</b> 24/7{t("customer support")}.
              </p>
              <p>
                <b>{t("Competitive Pricing")}:</b>
                {t("Affordable rates without compromising on quality")}.
              </p>
              <p>
                <b>{t("Reliability")}:</b>
                {t("Proven track record of safe and timely deliveries")}.
              </p>
            </Box>
          </Stack>
        </div>
      </div>
    );
  } else {
    return (
      <div className="about-page">
        <Container>
          <Stack className="about-top">
            <Box>
              <strong>{t("About us")}</strong>
              <p>
                {t(
                  "Founded in 2020, MX has been a leader in the car shipping industry, specializing in transporting vehicles from Korea to international destinations. Our team of experts ensures a smooth and hassle-free shipping experience for our clients"
                )}
                .
                <br />
                <br />
                {t(
                  `We offer a comprehensive range of services, including door-to-door shipping, customs clearance, and vehicle inspection. Our advanced logistics network and partnerships with leading carriers enable us to deliver top-notch service every time`
                )}
                .
                <br />
                <br />
                {t(
                  `Customer satisfaction is at the heart of everything we do. We are dedicated to continuous improvement and innovation, ensuring that we meet the evolving needs of our clients`
                )}
                .
              </p>
            </Box>
            <img src="/assets/images/about-img.png" alt="" />
          </Stack>
          <Stack className="about-center">
            <Box>
              <img src="/assets/images/about-center.png" alt="" />
            </Box>
            <Box className="right">
              <strong>{t("Why choose us")}</strong>
              <p>
                <b>{t("Experience")}:</b>{" "}
                {t("Over 4 years of industry experience")}.
              </p>
              <p>
                <b>{t("Global Network")}:</b>{" "}
                {t("Extensive network of partners and carriers")}.
              </p>
              <p>
                <b>{t("Customer Service")}:</b> 24/7{t("customer support")}.
              </p>
              <p>
                <b>{t("Competitive Pricing")}:</b>
                {t("Affordable rates without compromising on quality")}.
              </p>
              <p>
                <b>{t("Reliability")}:</b>
                {t("Proven track record of safe and timely deliveries")}.
              </p>
            </Box>
          </Stack>
        </Container>
      </div>
    );
  }
};

export default AboutPage;
