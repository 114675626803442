import React from "react";
import { Box, Button, Container, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useDeviceDetect from "../../../lib/detector";
import { useTranslation } from "react-i18next";

function AccordionUsage(props: any) {
  const { question, answer } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={question}
        id={question}
      >
        {question}
      </AccordionSummary>
      <AccordionDetails>{answer}</AccordionDetails>
    </Accordion>
  );
}

const FAQ = () => {
  const data = [
    {
      question: "Do you offer international shipping",
      answer:
        "Yes, we ship our vehicles to customers worldwide. Shipping costs and delivery times vary by destination.",
    },
    {
      question: "What warranty comes with the cars you sell?",
      answer:
        "Our vehicles come with the original manufacturer's warranty. Typically, this is a 3-year/60,000 km warranty, whichever comes first.",
    },
    {
      question:
        "Are your cars available in both left-hand and right-hand drive configurations?",
      answer:
        "No, we offer only left-hand drive models to accommodate different market requirements.",
    },
    {
      question: "Where do your cars come from?",
      answer: "We import all our vehicles from reputable Korean manufacturers.",
    },
    {
      question: "Can I resell the car in my country?",
      answer:
        "Yes, but please check local laws regarding the resale of imported vehicles.",
    },
  ];
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  if (isMobile()) {
    return (
      <div className="faq-frame">
        <div className="m-container">
          <Box className="faq-main">
            <span className="title">{t("Frequently Asked Questions")}?</span>
            <Box>
              {data.map((ele) => {
                return (
                  <AccordionUsage
                    question={t(ele.question)}
                    answer={t(ele.answer)}
                  />
                );
              })}
            </Box>
          </Box>
        </div>
      </div>
    );
  } else {
    return (
      <div className="faq-frame">
        <Container>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Box sx={{ width: "65%" }}>
              <span className="title">{t("Frequently Asked Questions")}?</span>
              <Box sx={{ mt: "30px" }}>
                {data.map((ele) => {
                  return (
                    <AccordionUsage
                      question={t(ele.question)}
                      answer={t(ele.answer)}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <img src="/assets/icons/faq.svg" alt="" />
            </Box>
          </Stack>
        </Container>
      </div>
    );
  }
};

export default FAQ;
