import { Stack, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useGlobals } from '../../hooks/useGlobals';
import {
  sweetConfirmAlert,
  sweetErrorHandling,
  sweetFailureProvider,
  sweetLoginConfirmAlert,
} from "../../../lib/sweetAlert";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Dispatch, createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import MemberList from "./memberList";
import NewProduct from "./NewProduct";
import ProductList from "./productList";
import { useGlobals } from "../../hooks/useGlobals";
import { MemberType } from "../../../lib/enums/member.enum";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AdminPage() {
  const { authMember, setAuthMember } = useGlobals();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const [title, setTitle] = useState<string>("Admin Sahifasi");
  const [desc, setDesc] = useState<string>("Admin boshqaruv paneli");

  useEffect(() => {
    if (authMember?.memberType !== MemberType.ADMIN) {
      history.push("/");
    }
  }, [authMember]);

  const changeTabHandler = (newTab: string) => {
    history.replace(`/admin/${newTab}`);
  };

  return (
    <div className="admin-page">
      <Stack className="tab-menu">
        <Box
          className={`tab ${
            location.pathname === "/admin/members" ? "active" : ""
          }`}
          onClick={() => changeTabHandler("members")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clipPath="url(#clip0_56_826)">
              <path
                d="M17.9543 15.4232C17.8752 15.0431 17.5028 14.7991 17.1226 14.8782C16.7424 14.9573 16.4984 15.3297 16.5776 15.7099C16.6232 15.9288 16.5689 16.1533 16.4287 16.3257C16.3474 16.4256 16.1664 16.5931 15.8662 16.5931H12.6913C12.303 16.5931 11.9882 16.9079 11.9882 17.2963C11.9882 17.6846 12.303 17.9994 12.6913 17.9994H15.8662C16.5106 17.9994 17.1132 17.7127 17.5197 17.213C17.9281 16.7107 18.0866 16.0584 17.9543 15.4232Z"
                fill="#696969"
              />
              <path
                d="M9.71423 9.58954C12.0242 9.8023 14.1255 11.0404 15.4342 12.9922C15.6505 13.3148 16.0873 13.401 16.4098 13.1847C16.7324 12.9684 16.8185 12.5316 16.6022 12.2091C15.4485 10.4883 13.773 9.23734 11.8628 8.60868C13.027 7.73578 13.7816 6.345 13.7816 4.78125C13.7816 2.14485 11.6367 0 9.00035 0C6.36398 0 4.2191 2.14485 4.2191 4.78125C4.2191 6.34602 4.97468 7.73761 6.14014 8.61036C5.07241 8.96186 4.07046 9.5085 3.19359 10.2297C1.58583 11.5522 0.467966 13.3969 0.0459854 15.4238C-0.0862723 16.059 0.0721768 16.7113 0.480622 17.2136C0.887028 17.7134 1.48971 18 2.13409 18H5.20347C5.59181 18 5.9066 17.6852 5.9066 17.2969C5.9066 16.9085 5.59181 16.5938 5.20347 16.5938H2.13402C1.83386 16.5938 1.6528 16.4262 1.57155 16.3264C1.43132 16.1539 1.37704 15.9294 1.42263 15.7104C2.11113 12.4034 4.9024 9.93772 8.21436 9.5973L6.87206 15.7043C6.82062 15.9385 6.89206 16.1828 7.06162 16.3524L8.46794 17.7589C8.59978 17.8908 8.77865 17.9648 8.96519 17.9648C9.15169 17.9648 9.33053 17.8907 9.4624 17.7589L10.8686 16.3524C11.0381 16.1828 11.1096 15.9385 11.0581 15.7043L9.71423 9.58954ZM5.62535 4.78125C5.62535 2.92025 7.13935 1.40625 9.00035 1.40625C10.8613 1.40625 12.3753 2.92025 12.3753 4.78125C12.3753 6.58607 10.9513 8.06446 9.16786 8.1521C9.11211 8.15108 9.05628 8.15041 9.00035 8.15041C8.94547 8.15041 8.89066 8.15122 8.83585 8.15221C7.05097 8.06611 5.62535 6.58709 5.62535 4.78125ZM8.96515 16.2673L8.32823 15.6302L8.96515 12.7325L9.60201 15.6303L8.96515 16.2673Z"
                fill="#696969"
              />
            </g>
            <defs>
              <clipPath id="clip0_56_826">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Foydalanuvchilar</span>
        </Box>

        <Box
          className={`tab ${
            location.pathname === "/admin/products" ? "active" : ""
          }`}
          onClick={() => changeTabHandler("products")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clipPath="url(#clip0_56_826)">
              <path
                d="M17.9543 15.4232C17.8752 15.0431 17.5028 14.7991 17.1226 14.8782C16.7424 14.9573 16.4984 15.3297 16.5776 15.7099C16.6232 15.9288 16.5689 16.1533 16.4287 16.3257C16.3474 16.4256 16.1664 16.5931 15.8662 16.5931H12.6913C12.303 16.5931 11.9882 16.9079 11.9882 17.2963C11.9882 17.6846 12.303 17.9994 12.6913 17.9994H15.8662C16.5106 17.9994 17.1132 17.7127 17.5197 17.213C17.9281 16.7107 18.0866 16.0584 17.9543 15.4232Z"
                fill="#696969"
              />
              <path
                d="M9.71423 9.58954C12.0242 9.8023 14.1255 11.0404 15.4342 12.9922C15.6505 13.3148 16.0873 13.401 16.4098 13.1847C16.7324 12.9684 16.8185 12.5316 16.6022 12.2091C15.4485 10.4883 13.773 9.23734 11.8628 8.60868C13.027 7.73578 13.7816 6.345 13.7816 4.78125C13.7816 2.14485 11.6367 0 9.00035 0C6.36398 0 4.2191 2.14485 4.2191 4.78125C4.2191 6.34602 4.97468 7.73761 6.14014 8.61036C5.07241 8.96186 4.07046 9.5085 3.19359 10.2297C1.58583 11.5522 0.467966 13.3969 0.0459854 15.4238C-0.0862723 16.059 0.0721768 16.7113 0.480622 17.2136C0.887028 17.7134 1.48971 18 2.13409 18H5.20347C5.59181 18 5.9066 17.6852 5.9066 17.2969C5.9066 16.9085 5.59181 16.5938 5.20347 16.5938H2.13402C1.83386 16.5938 1.6528 16.4262 1.57155 16.3264C1.43132 16.1539 1.37704 15.9294 1.42263 15.7104C2.11113 12.4034 4.9024 9.93772 8.21436 9.5973L6.87206 15.7043C6.82062 15.9385 6.89206 16.1828 7.06162 16.3524L8.46794 17.7589C8.59978 17.8908 8.77865 17.9648 8.96519 17.9648C9.15169 17.9648 9.33053 17.8907 9.4624 17.7589L10.8686 16.3524C11.0381 16.1828 11.1096 15.9385 11.0581 15.7043L9.71423 9.58954ZM5.62535 4.78125C5.62535 2.92025 7.13935 1.40625 9.00035 1.40625C10.8613 1.40625 12.3753 2.92025 12.3753 4.78125C12.3753 6.58607 10.9513 8.06446 9.16786 8.1521C9.11211 8.15108 9.05628 8.15041 9.00035 8.15041C8.94547 8.15041 8.89066 8.15122 8.83585 8.15221C7.05097 8.06611 5.62535 6.58709 5.62535 4.78125ZM8.96515 16.2673L8.32823 15.6302L8.96515 12.7325L9.60201 15.6303L8.96515 16.2673Z"
                fill="#696969"
              />
            </g>
            <defs>
              <clipPath id="clip0_56_826">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Mashinalar</span>
        </Box>

        <Box
          className={`tab ${
            location.pathname === "/admin/new-product" ? "active" : ""
          }`}
          onClick={() => changeTabHandler("new-product")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clipPath="url(#clip0_56_826)">
              <path
                d="M17.9543 15.4232C17.8752 15.0431 17.5028 14.7991 17.1226 14.8782C16.7424 14.9573 16.4984 15.3297 16.5776 15.7099C16.6232 15.9288 16.5689 16.1533 16.4287 16.3257C16.3474 16.4256 16.1664 16.5931 15.8662 16.5931H12.6913C12.303 16.5931 11.9882 16.9079 11.9882 17.2963C11.9882 17.6846 12.303 17.9994 12.6913 17.9994H15.8662C16.5106 17.9994 17.1132 17.7127 17.5197 17.213C17.9281 16.7107 18.0866 16.0584 17.9543 15.4232Z"
                fill="#696969"
              />
              <path
                d="M9.71423 9.58954C12.0242 9.8023 14.1255 11.0404 15.4342 12.9922C15.6505 13.3148 16.0873 13.401 16.4098 13.1847C16.7324 12.9684 16.8185 12.5316 16.6022 12.2091C15.4485 10.4883 13.773 9.23734 11.8628 8.60868C13.027 7.73578 13.7816 6.345 13.7816 4.78125C13.7816 2.14485 11.6367 0 9.00035 0C6.36398 0 4.2191 2.14485 4.2191 4.78125C4.2191 6.34602 4.97468 7.73761 6.14014 8.61036C5.07241 8.96186 4.07046 9.5085 3.19359 10.2297C1.58583 11.5522 0.467966 13.3969 0.0459854 15.4238C-0.0862723 16.059 0.0721768 16.7113 0.480622 17.2136C0.887028 17.7134 1.48971 18 2.13409 18H5.20347C5.59181 18 5.9066 17.6852 5.9066 17.2969C5.9066 16.9085 5.59181 16.5938 5.20347 16.5938H2.13402C1.83386 16.5938 1.6528 16.4262 1.57155 16.3264C1.43132 16.1539 1.37704 15.9294 1.42263 15.7104C2.11113 12.4034 4.9024 9.93772 8.21436 9.5973L6.87206 15.7043C6.82062 15.9385 6.89206 16.1828 7.06162 16.3524L8.46794 17.7589C8.59978 17.8908 8.77865 17.9648 8.96519 17.9648C9.15169 17.9648 9.33053 17.8907 9.4624 17.7589L10.8686 16.3524C11.0381 16.1828 11.1096 15.9385 11.0581 15.7043L9.71423 9.58954ZM5.62535 4.78125C5.62535 2.92025 7.13935 1.40625 9.00035 1.40625C10.8613 1.40625 12.3753 2.92025 12.3753 4.78125C12.3753 6.58607 10.9513 8.06446 9.16786 8.1521C9.11211 8.15108 9.05628 8.15041 9.00035 8.15041C8.94547 8.15041 8.89066 8.15122 8.83585 8.15221C7.05097 8.06611 5.62535 6.58709 5.62535 4.78125ZM8.96515 16.2673L8.32823 15.6302L8.96515 12.7325L9.60201 15.6303L8.96515 16.2673Z"
                fill="#696969"
              />
            </g>
            <defs>
              <clipPath id="clip0_56_826">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Yangi Mashina</span>
        </Box>
      </Stack>
      <Stack className="tab-content">
        <span className="main-title">{title}</span>
        <span className="main-desc">{desc}</span>
        <Stack className="content">
          <Switch>
            <Route path={"/admin/members"}>
              <MemberList />
            </Route>
            <Route path={"/admin/products"}>
              <ProductList />
            </Route>
            <Route path={"/admin/new-product"}>
              <NewProduct />
            </Route>
          </Switch>
        </Stack>
      </Stack>
    </div>
  );
}
