export enum CarStatus {
  PAUSE = "PAUSE",
  PROCESS = "PROCESS",
  DELETE = "DELETE",
}

export enum CarFuelType {
  LPG = "LPG",
  GASOLIE = "GASOLIE",
  HYBRID = "HYBRID",
  DIESEL = "DIESEL",
}

export enum CarSteeringType {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum CarColor {
  BLACK = "BLACK",
  WHITE = "WHITE",
  GRAY = "GRAY",
  SILVER = "SILVER",
  BLUE = "BLUE",
  RED = "RED",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  BROWN = "BROWN",
  GOLD = "GOLD",
  ORANGE = "ORANGE",
  PURPLE = "PURPLE",
  BEIGE = "BEIGE",
  MAROON = "MAROON",
  BURGUNDY = "BURGUNDY",
}

export enum CarBrand {
  BMW = "BMW",
  MERCEDES_BENZ = "MERCEDES-BENZ",
  KIA = "KIA",
  HYUNDAI = "HYUNDAI",
  AUDI = "AUDI",
  GENESIS = "GENESIS",
  LEXUS = "LEXUS",
  RANGE_ROVER = "RANGE-ROVER",
  CHEVROLET = "CHEVROLET",
  MASERATI = "MASERATI",
  LAMBORGHINI = "LAMBORGHINI",
  FERRARI = "FERRARI",
  ROLLS_ROYCE = "ROLLS-ROYCE",
  PORSCHE = "PORSCHE",
  BENTLEY = "BENTLEY",
  JEEP = "JEEP",
}
