import Products from "./Products";
import Services from "./Services";
import Advertisements from "./Advertisements";
import Contact from "./Contact";
import FAQ from "./Faq";
import useDeviceDetect from "../../../lib/detector";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const { isMobile } = useDeviceDetect();

  if (isMobile()) {
    return (
      <div className="m-homepage">
        <Products />
        <Services />
        <Advertisements />
        <Contact />
        <FAQ />
      </div>
    );
  } else {
    return (
      <div className="homepage">
        <Products />
        <Services />
        <Advertisements />
        <Contact />
        <FAQ />
      </div>
    );
  }
};

export default HomePage;
