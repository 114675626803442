import { useCallback, useEffect } from "react";
import { TLoginButton, TLoginButtonSize } from "react-telegram-auth";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";
import { Stack } from "@mui/material";
import MemberService from "../../services/MemberService";
import { useGlobals } from "../../hooks/useGlobals";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { botUsername } from "../../../lib/config";

const LoginPage = () => {
  const { setAuthMember } = useGlobals();
  const { t } = useTranslation();
  const history = useHistory();

  const doTelegramLogin = useCallback(async (tUser: any) => {
    console.warn("telegram-loginUser", tUser);

    const data = {
      memberFullName: tUser.last_name
        ? tUser.first_name + " " + tUser.last_name
        : tUser.first_name,
      memberTelegramId: tUser.id,
      memberTelegramUsername: tUser.username || "",
    };

    console.log("telegram-data", data);

    const member = new MemberService();
    const result = await member.login(data);

    setAuthMember(result);
    await sweetTopSmallSuccessAlert("successfully login");
    history.push("/");
    try {
    } catch (err: any) {
      console.warn("doTelegram err", err);
      await sweetErrorHandling(err.message);
    }
  }, []);

  console.log("botUsername: ", botUsername);
  return (
    <Stack className="login-page">
      <h1>{t("Login")}</h1>

      <p>{t("You can sign up with the SNS account you used before")}</p>

      <TLoginButton
        botName={botUsername}
        buttonSize={TLoginButtonSize.Large}
        lang="en"
        usePic={false}
        cornerRadius={20}
        onAuthCallback={async (tUser) => {
          await doTelegramLogin(tUser);
        }}
        requestAccess={"write"}
      />
    </Stack>
  );
};

export default LoginPage;
