import {
  Box,
  Button,
  Checkbox,
  Container,
  Pagination,
  Stack,
} from "@mui/material";
import useDeviceDetect from "../../../lib/detector";
import { useHistory } from "react-router-dom";
import { createSelector, Dispatch } from "@reduxjs/toolkit";
import { setCars } from "./slice";
import { Car, CarInquiry } from "../../../lib/types/car";
import { retrieveCars } from "./selector";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import CarService from "../../services/CarService";
import { CarBrand, CarColor } from "../../../lib/enums/car.enum";
import {
  carDistanceRanges,
  carModels,
  carPriceRanges,
  Messages,
  serverApi,
} from "../../../lib/config";
import { formatterStr } from "../../../lib/types/common";
import { sweetErrorHandling } from "../../../lib/sweetAlert";
import { useGlobals } from "../../hooks/useGlobals";
import MemberService from "../../services/MemberService";
import { LikeGroupType } from "../../../lib/enums/like.enum";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

/** REDUX SLICE & SELECTOR */
const actionDispatch = (dispatch: Dispatch) => ({
  setCars: (data: Car[]) => dispatch(setCars(data)),
});

const carsRetriever = createSelector(retrieveCars, (cars) => ({
  cars,
}));

export default function Products() {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  const { setCars } = actionDispatch(useDispatch());
  const { cars } = useSelector(carsRetriever);
  const { authMember } = useGlobals();
  const history = useHistory();
  const productsList = Array.from(Array(8).keys());
  const [inquiry, setInquiry] = useState<CarInquiry>({
    page: 1,
    limit: 8,
  });

  useEffect(() => {
    const car = new CarService();
    car
      .getCars(inquiry)
      .then((data) => {
        setCars(data);
      })
      .catch((err) => console.log(err));
  }, [inquiry]);

  const pushChosenDish = (id: string) => {
    console.log(pushChosenDish);
    history.push(`/products/${id}`);
  };

  const changeBrand = (e: any) => {
    const value = e.target.value;
    setInquiry({ ...inquiry, brand: value });
  };

  const changeModel = (e: any) => {
    const value = e.target.value;
    setInquiry({ ...inquiry, model: value });
  };

  const changeColor = (e: any) => {
    const value = e.target.value;
    setInquiry({ ...inquiry, color: value });
  };

  const changePrice = (e: any) => {
    let cleanedRange = e.target.value
      .split("-")
      .map((part: any) => part.replace(/,/g, ""));

    setInquiry({
      ...inquiry,
      price: {
        start: cleanedRange[0] ? parseInt(cleanedRange[0]) : 0,
        end: cleanedRange[1] ? parseInt(cleanedRange[1]) : 999999,
      },
    });
  };

  const changeDistance = (e: any) => {
    let cleanedRange = e.target.value
      .split("-")
      .map((part: any) => part.replace(/,/g, ""));

    setInquiry({
      ...inquiry,
      distance: {
        start: cleanedRange[0] ? parseInt(cleanedRange[0]) : 0,
        end: cleanedRange[1] ? parseInt(cleanedRange[1]) : 999999,
      },
    });
  };

  const paginationHandler = (e: ChangeEvent<any>, value: number) => {
    inquiry.page = value;
    setInquiry({ ...inquiry });
  };

  const likeProcessHandler = async (id: string) => {
    try {
      if (!authMember) throw new Error(Messages.error2);

      const member = new MemberService();
      const result = await member.likeProcess({
        likeRefId: id,
        groupType: LikeGroupType.CAR,
      });
      setInquiry({ ...inquiry });
    } catch (err: any) {
      console.log("Error: likeProcessHandler:", err);
      sweetErrorHandling(err);
    }
  };

  if (isMobile()) {
    return (
      <div className="m-product-list">
        <div className="m-container">
          <Stack className="filter-box">
            <strong>{t("Find your car")}</strong>
            <Stack className="filter">
              <Box>
                <span>{t("Brand")}</span>
                <select name="" id="" onChange={changeBrand}>
                  <option value="">{t("select")}</option>
                  {Object.values(CarBrand).map((brand: CarBrand) => (
                    <option value={brand}>{brand}</option>
                  ))}
                </select>
              </Box>
              <Box>
                <span>{t("Model")}</span>
                <select name="" id="" onChange={changeModel}>
                  <option value="">{t("select")}</option>
                  {inquiry?.brand &&
                    carModels[inquiry?.brand].map((brand: string) => (
                      <option value={brand}>{brand}</option>
                    ))}
                </select>
              </Box>
              <Box>
                <span>{t("Distance")}</span>
                <select name="" id="" onChange={changeDistance}>
                  <option value="">{t("select")}</option>
                  {carDistanceRanges.map((distance: string) => (
                    <option value={distance}>{distance}</option>
                  ))}
                </select>
              </Box>
              <Box>
                <span>{t("Price range")}</span>
                <select name="" id="" onChange={changePrice}>
                  <option value="">{t("select")}</option>
                  {carPriceRanges.map((price: string) => (
                    <option value={price}>{price}</option>
                  ))}
                </select>
              </Box>
              <Box>
                <span>{t("Color")}</span>
                <select name="" id="" onChange={changeColor}>
                  <option value="">{t("select")}</option>
                  {Object.values(CarColor).map((color: string) => (
                    <option value={color}>{color}</option>
                  ))}
                </select>
              </Box>
            </Stack>
          </Stack>
          <Stack className="product-wrap">
            {cars.length !== 0 ? (
              cars.map((car: Car, index: number) => (
                <Box
                  key={index}
                  sx={{
                    marginTop: "16px",
                    display: "flex",
                    gap: "16px",
                    borderRadius: "24px",
                    border: "1px solid #D7E1ED",
                    background: "#FFF",
                    padding: "11px 14px 7px 13px",
                    position: "relative",
                    flexDirection: "column",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: "24px",

                      pointerEvents: "none",
                      zIndex: 10,
                    },
                  }}
                >
                  <Box>
                    <img
                      src={`${serverApi}/${car.carImages[0]}`}
                      alt=""
                      className="car-img"
                      onClick={() => pushChosenDish(car._id)}
                    />
                  </Box>
                  <Box position={"relative"}>
                    <Box
                      sx={{
                        position: "absolute",
                        right: 0,
                        borderRadius: "var(--numbers-radius-base, 8px)",
                        border: "1px solid #D7E1ED",
                        background: "rgba(215, 225, 237, 0.20)",
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => likeProcessHandler(car._id)}
                    >
                      {car.meLiked && car?.meLiked[0]?.myFavorite ? (
                        <Favorite style={{ color: "red" }} />
                      ) : (
                        <FavoriteBorder />
                      )}
                    </Box>
                    <p className="car-price">${formatterStr(car.carPrice)}</p>
                    <p
                      className="car-title"
                      onClick={() => pushChosenDish(car._id)}
                    >
                      {car.carName}
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        mt: "14px",
                      }}
                    >
                      <div className="details">
                        <p className="car-details">{t("Year")}</p>
                        <p className="car-details">{car.carYear}</p>
                      </div>
                      <div className="details car-details-container">
                        <p className="car-details">{t("Color")}</p>
                        <p className="car-details">{car.carColor}</p>
                      </div>
                      <div className="details">
                        <p className="car-details">{t("Distance")}</p>
                        <p className="car-details">
                          {formatterStr(car.carDistance)}km
                        </p>
                      </div>
                    </Box>
                    <p className="car-location">
                      <img src="/assets/icons/world.svg" alt="" />
                      <span>{car.carLocation}</span>
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        mt: "14px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => pushChosenDish(car._id)}
                      >
                        {t("View more")}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => pushChosenDish(car._id)}
                      >
                        {t("Get in touch")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <div className={"no-data"}>
                <img src="/assets/icons/icoAlert.svg" alt="" />
                <p>{t("No Cars found")}!</p>
              </div>
            )}
          </Stack>
          <Stack alignItems={"center"} marginTop={"30px"}>
            <Pagination
              count={cars.length !== 0 ? inquiry.page + 1 : inquiry.page}
              page={inquiry.page}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={paginationHandler}
            />
          </Stack>
        </div>
      </div>
    );
  } else {
    return (
      <div className="products-container">
        <Container
          maxWidth="lg"
          sx={{
            position: "relative",
          }}
        >
          <Stack flexDirection={"row"} justifyContent={"center"}>
            <span className="title">{t("Find your car")}</span>
          </Stack>
          <Stack className="filter-box">
            <Box className="filter">
              <span>{t("Brand")}</span>
              <select name="" id="" onChange={changeBrand}>
                <option value="">{t("Select")}</option>
                {Object.values(CarBrand).map((brand: CarBrand) => (
                  <option value={brand}>{brand}</option>
                ))}
              </select>
            </Box>
            <Box className="filter">
              <span>{t("Model")}</span>
              <select name="" id="" onChange={changeModel}>
                <option value="">{t("Select")}</option>
                {inquiry?.brand &&
                  carModels[inquiry?.brand].map((brand: string) => (
                    <option value={brand}>{brand}</option>
                  ))}
              </select>
            </Box>
            <Box className="filter">
              <span>{t("Distance")}</span>
              <select name="" id="" onChange={changeDistance}>
                <option value="">{t("Select")}</option>
                {carDistanceRanges.map((distance: string) => (
                  <option value={distance}>{distance}</option>
                ))}
              </select>
            </Box>
            <Box className="filter">
              <span>{t("Price range")}</span>
              <select name="" id="" onChange={changePrice}>
                <option value="">{t("Select")}</option>
                {carPriceRanges.map((price: string) => (
                  <option value={price}>{price}</option>
                ))}
              </select>
            </Box>
            <Box className="filter">
              <span>{t("Color")}</span>
              <select name="" id="" onChange={changeColor}>
                <option value="">{t("Select")}</option>
                {Object.values(CarColor).map((color: string) => (
                  <option value={color}>{color}</option>
                ))}
              </select>
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
              mt: "20px",
            }}
          >
            {cars.length !== 0 ? (
              cars.map((car: Car, index: number) => (
                <Box
                  key={index}
                  sx={{
                    width: "628px",
                    display: "flex",
                    gap: "16px",
                    borderRadius: "24px",

                    border: "1px solid #D7E1ED",
                    background: "#FFF",
                    padding: "11px 14px 7px 13px",
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: "24px",
                      pointerEvents: "none",
                      zIndex: 10,
                    },
                  }}
                >
                  <Box>
                    <img
                      src={`${serverApi}/${car.carImages[0]}`}
                      alt=""
                      className="car-box-img"
                      onClick={() => pushChosenDish(car._id)}
                    />
                  </Box>
                  <Box>
                    <p className="car-price">${formatterStr(car.carPrice)}</p>
                    <p
                      className="car-title"
                      onClick={() => pushChosenDish(car._id)}
                    >
                      {car.carName}
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        mt: "14px",
                      }}
                    >
                      <div>
                        <p className="car-details">{t("Year")}</p>
                        <p className="car-details">{car.carYear}</p>
                      </div>
                      <div className="car-details-container">
                        <p className="car-details">{t("Color")}</p>
                        <p className="car-details">{car.carColor}</p>
                      </div>
                      <div>
                        <p className="car-details">{t("Distance")}</p>
                        <p className="car-details">
                          {formatterStr(car.carDistance)}km
                        </p>
                      </div>
                    </Box>
                    <p className="car-location">
                      <img src="/assets/icons/world.svg" alt="" />
                      <span>{car.carLocation}</span>
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        mt: "14px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => pushChosenDish(car._id)}
                      >
                        {t("View more")}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => pushChosenDish(car._id)}
                      >
                        {t("Get in touch")}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      right: "12px",
                      borderRadius: "var(--numbers-radius-base, 8px)",
                      border: "1px solid #D7E1ED",
                      background: "rgba(215, 225, 237, 0.20)",
                      width: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => likeProcessHandler(car._id)}
                  >
                    {car.meLiked && car?.meLiked[0]?.myFavorite ? (
                      <Favorite style={{ color: "red" }} />
                    ) : (
                      <FavoriteBorder />
                    )}
                  </Box>
                </Box>
              ))
            ) : (
              <div className={"no-data"}>
                <img src="/assets/icons/icoAlert.svg" alt="" />
                <p>{t("No Cars found")}!</p>
              </div>
            )}
          </Stack>
          <Stack alignItems={"center"} marginTop={"30px"}>
            <Pagination
              count={cars.length !== 0 ? inquiry.page + 1 : inquiry.page}
              page={inquiry.page}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={paginationHandler}
            />
          </Stack>
        </Container>
      </div>
    );
  }
}
