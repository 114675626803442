import { Box } from "@mui/material";
import useDeviceDetect from "../../../lib/detector";
import Top from "./Top";
import { useTranslation } from "react-i18next";

const OtherNavbar = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  if (isMobile()) {
    return (
      <div className="mobile-other-navbar">
        <div className="navbar-frame">
          <div className="top-menu">
            <Top other={true} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pc-navbar">
        <div className="other-navbar">
          <div className="navbar-config">
            <Top other={true} />
          </div>
        </div>
      </div>
    );
  }
};

export default OtherNavbar;
