import axios from "axios";
import { serverApi } from "../../lib/config";
import {
  LoginInput,
  Member,
  MemberInput,
  MemberInquiry,
  MemberUpdateInput,
} from "../../lib/types/member";
import { LikeGroupType } from "../../lib/enums/like.enum";
import { Like, LikeInput } from "../../lib/types/like";

class MemberService {
  private readonly path: string;

  constructor() {
    this.path = serverApi;
  }

  public async login(input: LoginInput): Promise<Member> {
    try {
      const url = this.path + "/member/login";
      const result = await axios.post(url, input, { withCredentials: true });
      console.log("login:", result);

      const member: Member = result.data.member;
      console.log("member:", member);
      localStorage.setItem("memberData", JSON.stringify(member));

      return member;
    } catch (err) {
      console.log("Error, login:", err);
      throw err;
    }
  }

  public async logout(): Promise<void> {
    try {
      const url = this.path + "/member/logout";
      const result = await axios.post(url, {}, { withCredentials: true });
      console.log("logout:", result);

      localStorage.removeItem("memberData");
    } catch (err) {
      console.log("Error, logout:", err);
      throw err;
    }
  }

  public async likeProcess(input: LikeInput): Promise<Like> {
    try {
      const url = this.path + "/member/like";
      const result = await axios.post(url, input, { withCredentials: true });
      console.log("likeProcess:", result);

      return result.data;
    } catch (err) {
      console.log("Error, logout:", err);
      throw err;
    }
  }

  public async getAllMembers(input: any): Promise<Member[]> {
    try {
      const url =
        this.path + `/admin/member/all?page=${input.page}&limit=${input.limit}`;
      const result = await axios.get(url, { withCredentials: true });
      console.log("getAllMembers:", result);

      return result.data;
    } catch (err) {
      console.log("Error, getAllMembers:", err);
      throw err;
    }
  }

  public async updateMember(
    id: string,
    data: MemberUpdateInput
  ): Promise<Member> {
    try {
      const result = await axios.post(this.path + `/admin/member/${id}`, data, {
        withCredentials: true,
      });
      console.log("updateMember:", result);

      return result.data;
    } catch (err) {
      console.log("Error, updateMember:", err);
      throw err;
    }
  }
}

export default MemberService;
