import {
  Box,
  Link,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { createSelector, Dispatch } from "@reduxjs/toolkit";
import { setMembers } from "./slice";
import {
  Member,
  MemberInquiry,
  MemberUpdateInput,
} from "../../../lib/types/member";
import { retrieveMembers } from "./selector";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MemberService from "../../services/MemberService";
import { MemberStatus } from "../../../lib/enums/member.enum";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F7FC",
    color: "#1967D2",
    border: "none",
    padding: "25px 30px",
    [`&.${tableCellClasses.head}:first-child`]: {
      borderRadius: "8px 0 0 8px",
    },
    [`&.${tableCellClasses.head}:last-child`]: {
      borderRadius: "0 8px 8px 0px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  td: {
    padding: "30px 0px",
    border: "none",
    borderBottom: "1px solid #ECEDF2",
    [`&:first-child, &:last-child`]: {
      padding: "30px 30px",
    },
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/** REDUX SLICE & SELECTOR */
const actionDispatch = (dispatch: Dispatch) => ({
  setMembers: (data: Member[]) => dispatch(setMembers(data)),
});

const membersRetriever = createSelector(retrieveMembers, (members) => ({
  members,
}));

export default function MemberList() {
  const { setMembers } = actionDispatch(useDispatch());
  const { members } = useSelector(membersRetriever);
  const [filterName, setFilterName] = useState<string>("All");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortingOpen, setSortingOpen] = useState(false);
  const [statusAnchor, setStatusAnchor] = useState<[] | HTMLElement[]>([]);
  const [inquiry, setInquiry] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    const member = new MemberService();
    member
      .getAllMembers(inquiry)
      .then((data) => {
        setMembers(data);
      })
      .catch((err) => console.log(err));
  }, [inquiry]);

  const sortingClickHandler = (e: any) => {
    setAnchorEl(e.currentTarget);
    setSortingOpen(true);
  };

  const sortingCloseHandler = () => {
    setSortingOpen(false);
    setAnchorEl(null);
  };

  const statusClickHandler = (e: any, index: number) => {
    const tempAnchor = statusAnchor.slice();
    tempAnchor[index] = e.currentTarget;
    setStatusAnchor(tempAnchor);
  };
  const statusCloseHandler = () => {
    setStatusAnchor([]);
  };

  const sortingHandler = (e: React.MouseEvent<HTMLLIElement>) => {
    // if (e.currentTarget.id === 'All') delete applicantSearch?.inquiry?.applicationStatus;
    // else applicantSearch.inquiry.applicationStatus = e.currentTarget.id;
    // setApplicantSearch({ ...applicantSearch });
    // setFilterName(e.currentTarget.id);
    // setSortingOpen(false);
    // setAnchorEl(null);
  };

  const paginationHandler = (e: any, value: number) => {
    inquiry.page = value;
    setInquiry({ ...inquiry });
  };

  const updateMemberHandler = async (
    id: string,
    updateData: MemberUpdateInput
  ) => {
    try {
      const member = new MemberService();

      await member.updateMember(id, updateData);

      setInquiry({ ...inquiry });

      await sweetTopSmallSuccessAlert("successfully updated!", 700);
    } catch (err) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };

  return (
    <Stack className="wrap">
      <Box className="filter-box">
        <span className="title">Foydalanuvchuilar</span>
        <div className="filter">
          <div className="sort-box"></div>
        </div>
      </Box>
      <Box className="table-wrap">
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Member Nick</StyledTableCell>
                <StyledTableCell align="center">Id</StyledTableCell>
                <StyledTableCell align="center">Azolik Turi</StyledTableCell>
                <StyledTableCell align="center">A'zo bo'lgan</StyledTableCell>
                <StyledTableCell align="center">Xolati</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.length !== 0 ? (
                members.map((member: Member, index: number) => {
                  const imagePath = "/assets/icons/default-user.svg";

                  return (
                    <StyledTableRow key={"index"}>
                      <StyledTableCell>
                        <div className="img-box">
                          <img src={imagePath} alt="" className="user-img" />
                          <span>
                            {member.memberFullName ??
                              member.memberTelegramUsername}
                          </span>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {member.memberTelegramId}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <span>{member.memberType}</span>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(member.createdAt).format("YY-MM-DD HH:mm")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div
                          className="data-status"
                          onClick={(e: any) => statusClickHandler(e, index)}
                        >
                          {member.memberStatus}
                        </div>
                        <Menu
                          sx={{ mt: "20px" }}
                          anchorEl={statusAnchor[index]}
                          open={Boolean(statusAnchor[index])}
                          onClose={statusCloseHandler}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          {Object.values(MemberStatus)
                            .filter((ele) => ele !== member.memberStatus)
                            .map((status: MemberStatus) => {
                              return (
                                <MenuItem
                                  key={status}
                                  onClick={() => {
                                    statusCloseHandler();
                                    updateMemberHandler(member._id, {
                                      memberStatus: status,
                                    });
                                  }}
                                >
                                  {status}
                                </MenuItem>
                              );
                            })}
                        </Menu>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="no-data">
                      <InfoOutlinedIcon />
                      <span>No data found!</span>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack className="pagination-box">
        <Pagination
          color="primary"
          count={members.length !== 0 ? inquiry.page + 1 : inquiry.page}
          page={inquiry.page}
          onChange={paginationHandler}
        />
      </Stack>
    </Stack>
  );
}
