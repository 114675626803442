import { TelegramMessage } from "./types/common";

export const serverApi: string = `${process.env.REACT_APP_API_URL}`;
export const botUsername: string = `${process.env.REACT_APP_BOT_USERNAME}`;

export const sendTelegram = (message: TelegramMessage) => {
  let data;
  if (message.carName) {
    message.carImage = `${serverApi}/${message.carImage}`;
    data = `name: ${message.name}\nphone: ${message.phone}\ncarName: ${message.carName}\ncarBrand: ${message.carBrand}\ncarModel: ${message.carModel}\ncarYear: ${message.carYear}\ncarColor: ${message.carColor}\ncarImage: ${message.carImage} \nmessage: ${message.text}`;
  } else {
    data = `name: ${message.name}\nphone: ${message.phone}\nmessage: ${message.text}`;
  }

  const telegramUrl = `https://t.me/hurshid_13?text=${encodeURIComponent(
    data
  )}`;

  window.open(telegramUrl, "_blank");
};

export const Messages = {
  error1: "Something went wrong!",
  error2: "Please login in first!",
  error3: "Please fulfill all inputs!",
  error4: "Message is empty!",
  error5: "Only images with jpeg, jpg, png format allowed!",
};

export const ColorUz = {
  BLACK: "QORA",
  WHITE: "OQ",
  GRAY: "KULRANG",
  SILVER: "KUMUSH",
  BLUE: "KO'K",
  RED: "QIZIL",
  GREEN: "YASHIL",
  YELLOW: "SARIQ",
  BROWN: "JIGARRANG",
  GOLD: "OLTIN",
  ORANGE: "APELSIN RANG",
  PURPLE: "BINAFSHA",
  BEIGE: "BEJ",
  MAROON: "TO'Q QIZIL",
  BURGUNDY: "BORDO",
};

export const carPriceRanges = [
  "0-10,000",
  "10,000-20,000",
  "20,000-30,000",
  "30,000-40,000",
  "40,000-50,000",
  "50,000-60,000",
  "60,000-70,000",
  "70,000-80,000",
  "80,000-90,000",
  "90,000-100,000",
  "100,000-150,000",
  "150,000-200,000",
  "200,000+",
];

export const carDistanceRanges = [
  "0-10,000",
  "10,000-20,000",
  "20,000-30,000",
  "30,000-40,000",
  "40,000-50,000",
  "50,000-60,000",
  "60,000-70,000",
  "70,000-80,000",
  "80,000-90,000",
  "90,000-100,000",
];

export const carModels = {
  BMW: [
    "1 Series",
    "2 Series",
    "3 Series",
    "4 Series",
    "5 Series",
    "6 Series",
    "7 Series",
    "8 Series",
    "X1",
    "X2",
    "X3",
    "X4",
    "X5",
    "X6",
    "X7",
    "Z4",
    "i3",
    "i4",
    "i8",
    "iX",
    "M2",
    "M3",
    "M4",
    "M5",
    "M8",
  ],
  "MERCEDES-BENZ": [
    "A-Class",
    "B-Class",
    "C-Class",
    "E-Class",
    "S-Class",
    "CLA",
    "CLS",
    "GLA",
    "GLB",
    "GLC",
    "GLE",
    "GLS",
    "G-Class",
    "EQC",
    "EQA",
    "EQB",
    "EQS",
    "AMG GT",
  ],
  KIA: [
    "Rio",
    "Forte",
    "K5 (Optima)",
    "Stinger",
    "Seltos",
    "Sportage",
    "Sorento",
    "Telluride",
    "Soul",
    "Niro",
    "Carnival (Sedona)",
    "EV6",
  ],
  HYUNDAI: [
    "Accent",
    "Elantra",
    "Sonata",
    "Ioniq",
    "Veloster",
    "Venue",
    "Kona",
    "Tucson",
    "Santa Fe",
    "Palisade",
    "Nexo",
  ],
  AUDI: [
    "A1",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "Q2",
    "Q3",
    "Q5",
    "Q7",
    "Q8",
    "TT",
    "R8",
    "e-tron",
    "RS Models",
  ],
  GENESIS: ["G70", "G80", "G90", "GV70", "GV80"],
  LEXUS: [
    "CT",
    "IS",
    "ES",
    "GS",
    "LS",
    "UX",
    "NX",
    "RX",
    "GX",
    "LX",
    "RC",
    "LC",
  ],
  TOYOTA: [
    "Yaris",
    "Corolla",
    "Camry",
    "Avalon",
    "Prius",
    "Supra",
    "86",
    "C-HR",
    "RAV4",
    "Highlander",
    "4Runner",
    "Sequoia",
    "Land Cruiser",
    "Sienna",
    "Tacoma",
    "Tundra",
    "Mirai",
  ],
  "RANGE-ROVER": [
    "Range Rover",
    "Range Rover Sport",
    "Range Rover Velar",
    "Range Rover Evoque",
    "Discovery",
    "Discovery Sport",
    "Defender",
  ],
  CHEVROLET: [
    "Spark",
    "Sonic",
    "Malibu",
    "Impala",
    "Bolt EV",
    "Camaro",
    "Corvette",
    "Trax",
    "Trailblazer",
    "Equinox",
    "Blazer",
    "Traverse",
    "Tahoe",
    "Suburban",
    "Colorado",
    "Silverado",
    "Silverado HD",
  ],
  MASERATI: [
    "Ghibli",
    "Quattroporte",
    "Levante",
    "GranTurismo",
    "GranCabrio",
    "MC20",
  ],
  LAMBORGHINI: ["Huracán", "Aventador", "Urus", "Sián", "Centenario", "Veneno"],
  FERRARI: [
    "Portofino",
    "Roma",
    "F8 Tributo",
    "812 Superfast",
    "488 Pista",
    "SF90 Stradale",
    "GTC4Lusso",
    "LaFerrari",
  ],
  "ROLLS-ROYCE": ["Phantom", "Ghost", "Wraith", "Dawn", "Cullinan"],
  PORSCHE: [
    "718 (Boxster/Cayman)",
    "911",
    "Taycan",
    "Panamera",
    "Macan",
    "Cayenne",
  ],
  BENTLEY: ["Continental GT", "Flying Spur", "Bentayga", "Mulsanne"],
  JEEP: [
    "Renegade",
    "Compass",
    "Cherokee",
    "Grand Cherokee",
    "Grand Cherokee L",
    "Wrangler",
    "Wrangler Unlimited",
    "Gladiator",
  ],
};

export const carLocation = [
  "Korea",
  "United Arab Emirates",
  "Armenia",
  "Azerbaijan",
  "Belarus",
  "Kazakhstan",
  "Kyrgyzstan",
  "Moldova",
  "Russia",
  "Tajikistan",
  "Uzbekistan",
  "USA",
  "Albania",
  "Andorra",
  "Austria",
  "Belarus",
  "Belgium",
  "Bosnia and Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "European Union",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Moldova",
  "Monaco",
  "Montenegro",
  "Netherlands",
  "North Macedonia",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Ukraine",
  "United Kingdom",
];
