import { Box, Container, Stack } from "@mui/material";
import useDeviceDetect from "../../../lib/detector";
import { useTranslation } from "react-i18next";

const ServicePage = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  if (isMobile()) {
    return (
      <div className="m-service-page">
        <div className="m-container">
          <Stack className="service-box">
            <img
              src="/assets/images/service-1.png"
              alt=""
              className="main-img"
            />
            <div className="box">
              <img src="/assets/icons/adv-1.svg" alt="" />
              <div>
                <span>{t("Door to door shipping")}</span>
                <p>
                  {t(
                    `We provide convenient door-to-door shipping services, picking up your car from your location in Korea and delivering it directly to your specified destination`
                  )}
                  .
                </p>
              </div>
            </div>
          </Stack>
          <Stack className="service-box">
            <img
              src="/assets/images/service-2.png"
              alt=""
              className="main-img"
            />
            <div className="box right">
              <img src="/assets/icons/adv-2.svg" alt="" />
              <div>
                <span>{t("Customer clearance")}</span>
                <p>
                  {t(
                    `Our team handles all the customs clearance processes, ensuring your car meets all import/export regulations and arrives without any legal hassles`
                  )}
                </p>
              </div>
            </div>
          </Stack>
          <Stack className="service-box">
            <img
              src="/assets/images/service-3.png"
              alt=""
              className="main-img"
            />
            <div className="box ">
              <img src="/assets/icons/adv-3.svg" alt="" />
              <div>
                <span>{t("Secure transport")}</span>
                <p>
                  {t(
                    `We prioritize the safety of your vehicle with our secure transport services, including enclosed shipping options and real-time tracking`
                  )}
                  .
                </p>
              </div>
            </div>
          </Stack>
          <Stack className="service-box">
            <img
              src="/assets/images/service-4.png"
              alt=""
              className="main-img"
            />
            <div className="box ">
              <img src="/assets/icons/adv-4.svg" alt="" />
              <div>
                <span>{t("Vehicle Inspection and Preparation")}</span>
                <p>
                  {t(
                    `Our comprehensive inspection and preparation service ensures your car is ready for shipping, including cleaning, securing, and detailed documentation`
                  )}
                  .
                </p>
              </div>
            </div>
          </Stack>
          <Stack className="service-box">
            <img
              src="/assets/images/service-5.png"
              alt=""
              className="main-img"
            />
            <div className="box ">
              <img src="/assets/icons/adv-5.svg" alt="" />
              <div>
                <span>{t("International Shipping")}</span>
                <p>
                  {t(
                    `We offer reliable international shipping services to various destinations worldwide, utilizing our extensive network and expertise in global logistics`
                  )}
                  .
                </p>
              </div>
            </div>
          </Stack>
        </div>
      </div>
    );
  } else {
    return (
      <div className="service-page">
        <Container>
          <Box className="service-box">
            <div className="box">
              <img src="/assets/icons/adv-1.svg" alt="" />
              <div>
                <span>{t("Door to door shipping")}</span>
                <p>
                  {t(
                    `We provide convenient door-to-door shipping services, picking up your car from your location in Korea and delivering it directly to your specified destination`
                  )}
                  .
                </p>
              </div>
            </div>
            <img src="/assets/images/service-1.png" alt="" />
          </Box>
          <Box className="service-box">
            <img src="/assets/images/service-2.png" alt="" />
            <div className="box right">
              <div>
                <span>{t("Customer clearance")}</span>
                <p>
                  {t(
                    `Our team handles all the customs clearance processes, ensuring your car meets all import/export regulations and arrives without any legal hassles`
                  )}
                </p>
              </div>
              <img src="/assets/icons/adv-2.svg" alt="" />
            </div>
          </Box>
          <Box className="service-box">
            <div className="box">
              <img src="/assets/icons/adv-3.svg" alt="" />
              <div>
                <span>{t("Secure transport")}</span>
                <p>
                  {t(
                    `We prioritize the safety of your vehicle with our secure transport services, including enclosed shipping options and real-time tracking`
                  )}
                  .
                </p>
              </div>
            </div>
            <img src="/assets/images/service-3.png" alt="" />
          </Box>
          <Box className="service-box">
            <img src="/assets/images/service-4.png" alt="" />
            <div className="box right">
              <div>
                <span>{t("Vehicle Inspection and Preparation")}</span>
                <p>
                  {t(
                    `Our comprehensive inspection and preparation service ensures your car is ready for shipping, including cleaning, securing, and detailed documentation`
                  )}
                  .
                </p>
              </div>
              <img src="/assets/icons/adv-4.svg" alt="" />
            </div>
          </Box>
          <Box className="service-box">
            <div className="box">
              <img src="/assets/icons/adv-5.svg" alt="" />
              <div>
                <span>{t("International Shipping")}</span>
                <p>
                  {t(
                    `We offer reliable international shipping services to various destinations worldwide, utilizing our extensive network and expertise in global logistics`
                  )}
                  .
                </p>
              </div>
            </div>
            <img src="/assets/images/service-5.png" alt="" />
          </Box>
        </Container>
      </div>
    );
  }
};

export default ServicePage;
