import {
  Box,
  Container,
  Button,
  Stack,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { CaretDown } from "phosphor-react";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import useDeviceDetect from "../../../lib/detector";
import MenuIcon from "@mui/icons-material/Menu";

import { useGlobals } from "../../hooks/useGlobals";
import MemberService from "../../services/MemberService";
import {
  sweetErrorHandling,
  sweetTopSuccessAlert,
} from "../../../lib/sweetAlert";
import { Messages } from "../../../lib/config";
import { Logout } from "@mui/icons-material";
import { MemberType } from "../../../lib/enums/member.enum";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Divider from "@mui/material/Divider";

interface TopProps {
  other: boolean;
}

const Top = (props: TopProps) => {
  const { other } = props;
  const { setAuthMember } = useGlobals();
  const { isMobile } = useDeviceDetect();
  const { authMember } = useGlobals();
  const history = useHistory();
  const { t } = useTranslation();
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [lang, setLang] = useState<string | null>("en");
  const drop = Boolean(anchorEl2);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const langClick = (e: any) => {
    setAnchorEl2(e.currentTarget);
  };

  const langClose = () => {
    setAnchorEl2(null);
  };

  const langChoice = (e: any) => {
    setLang(e.target.id);
    i18n.changeLanguage(e.target.id);
    setAnchorEl2(null);
  };

  const handleLogoutClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseLogout = () => setAnchorEl(null);

  const handleLogoutRequest = async () => {
    try {
      const member = new MemberService();
      await member.logout();

      await sweetTopSuccessAlert("success", 700);
      setAuthMember(null);
    } catch (err) {
      console.log(err);
      sweetErrorHandling(Messages.error1);
    }
  };

  const adminPageHandler = () => {
    history.push("/admin/members");
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      top: "109px",
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 160,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  if (isMobile()) {
    return (
      <div className="home-navbar">
        <div className="m-container">
          <Box>
            <NavLink to={"/"}>
              <img
                src={`/assets/images/${other ? "logo_black.svg" : "logo.png"}`}
                alt=""
                className="logo"
              />
            </NavLink>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
            alignItems={"center"}
          >
            <div className="lan-box">
              <Button
                disableRipple
                className="btn-lang"
                onClick={langClick}
                endIcon={<CaretDown size={14} color="#616161" weight="fill" />}
              >
                <Box component={"div"} className={"flag"} display={"flex"}>
                  {i18n.language !== null ? (
                    <img
                      src={`/assets/flag/lang${i18n.language}.png`}
                      alt={"usaFlag"}
                    />
                  ) : (
                    <img src={`/assets/flag/langen.png`} alt={"usaFlag"} />
                  )}
                </Box>
              </Button>

              <StyledMenu
                anchorEl={anchorEl2}
                open={drop}
                onClose={langClose}
                sx={{ position: "absolute" }}
              >
                <MenuItem disableRipple onClick={langChoice} id="en">
                  <img
                    className="img-flag"
                    src={"/assets/flag/langen.png"}
                    onClick={langChoice}
                    id="en"
                    alt={"usaFlag"}
                  />
                  {"English"}
                </MenuItem>
                <MenuItem disableRipple onClick={langChoice} id="kr">
                  <img
                    className="img-flag"
                    src={"/assets/flag/langkr.png"}
                    onClick={langChoice}
                    id="uz"
                    alt={"koreanFlag"}
                  />
                  {"Korean"}
                </MenuItem>
                <MenuItem disableRipple onClick={langChoice} id="ru">
                  <img
                    className="img-flag"
                    src={"/assets/flag/langru.png"}
                    onClick={langChoice}
                    id="ru"
                    alt={"russiaFlag"}
                  />
                  {"Russian"}
                </MenuItem>
              </StyledMenu>
            </div>
            <div>
              <MenuIcon
                style={{ color: other ? "black" : "white" }}
                fontSize="large"
                sx={{ ml: "20px" }}
                onClick={toggleDrawer(true)}
              />

              <Drawer
                anchor={"right"}
                open={open}
                onClose={toggleDrawer(false)}
              >
                {authMember && (
                  <>
                    <Box
                      className="login-user"
                      sx={{ padding: "8px 16px" }}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src="/assets/icons/default-user.svg"
                      />
                      <Typography
                        variant="h4"
                        component="h4"
                        sx={{ ml: "10px" }}
                      >
                        {authMember?.memberFullName ??
                          authMember?.memberTelegramId}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                )}
                <Box
                  sx={{ width: 250 }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                >
                  <List>
                    {!authMember && (
                      <ListItem key={"login"} disablePadding>
                        <ListItemButton>
                          <NavLink
                            to={"/login"}
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemText
                              primary={t("Login")}
                              style={{
                                color: "#1E1E1E",
                                fontFamily: "Rubik",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                textDecoration: "none",
                              }}
                            />
                          </NavLink>
                        </ListItemButton>
                      </ListItem>
                    )}
                    {[
                      { name: "Cars", link: "/products" },
                      { name: "Services", link: "/service" },
                      { name: "About us", link: "/about" },
                      { name: "Contacts", link: "/contact" },
                    ].map((ele, index) => (
                      <ListItem key={ele.name} disablePadding>
                        <ListItemButton>
                          <NavLink
                            to={ele.link}
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemText
                              primary={t(ele.name)}
                              style={{
                                color: "#1E1E1E",
                                fontFamily: "Rubik",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                textDecoration: "none",
                              }}
                            />
                          </NavLink>
                        </ListItemButton>
                      </ListItem>
                    ))}
                    {authMember && (
                      <ListItem key={"logout"} disablePadding>
                        <ListItemButton onClick={handleLogoutRequest}>
                          <ListItemText
                            primary={t("Logout")}
                            style={{
                              color: "#1E1E1E",
                              fontFamily: "Rubik",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              textDecoration: "none",
                            }}
                            onClick={handleLogoutRequest}
                          />
                        </ListItemButton>
                      </ListItem>
                    )}
                  </List>
                </Box>
              </Drawer>
            </div>
          </Box>
        </div>
      </div>
    );
  } else {
    return (
      <Container maxWidth="lg">
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box>
            <NavLink to={"/"}>
              <img
                src={`/assets/images/${other ? "logo_black.svg" : "logo.png"}`}
                alt=""
                className="logo"
              />
            </NavLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 5,
            }}
            alignItems={"center"}
          >
            <NavLink className={"menu-names"} to={"/"}>
              {t("Home")}
            </NavLink>
            <NavLink className={"menu-names"} to={"/products"}>
              {t("Cars")}
            </NavLink>
            <NavLink className={"menu-names"} to={"/service"}>
              {t("Services")}
            </NavLink>
            <NavLink className={"menu-names"} to={"/about"}>
              {t("About us")}
            </NavLink>
            <NavLink className={"menu-names"} to={"/contact"}>
              {t("Contacts")}
            </NavLink>
            <Box display={"flex"} flexDirection={"row"}>
              <div className="lan-box">
                <Button
                  disableRipple
                  className="btn-lang"
                  onClick={langClick}
                  endIcon={
                    <CaretDown size={14} color="#616161" weight="fill" />
                  }
                >
                  <Box component={"div"} className={"flag"} display={"flex"}>
                    {i18n.language !== null ? (
                      <img
                        src={`/assets/flag/lang${i18n.language}.png`}
                        alt={"usaFlag"}
                      />
                    ) : (
                      <img src={`/assets/flag/langen.png`} alt={"usaFlag"} />
                    )}
                  </Box>
                </Button>

                <StyledMenu
                  anchorEl={anchorEl2}
                  open={drop}
                  onClose={langClose}
                  sx={{ position: "absolute" }}
                >
                  <MenuItem disableRipple onClick={langChoice} id="en">
                    <img
                      className="img-flag"
                      src={"/assets/flag/langen.png"}
                      onClick={langChoice}
                      id="en"
                      alt={"usaFlag"}
                    />
                    {"English"}
                  </MenuItem>
                  <MenuItem disableRipple onClick={langChoice} id="kr">
                    <img
                      className="img-flag"
                      src={"/assets/flag/langkr.png"}
                      onClick={langChoice}
                      id="uz"
                      alt={"koreanFlag"}
                    />
                    {"Korean"}
                  </MenuItem>
                  <MenuItem disableRipple onClick={langChoice} id="ru">
                    <img
                      className="img-flag"
                      src={"/assets/flag/langru.png"}
                      onClick={langChoice}
                      id="ru"
                      alt={"russiaFlag"}
                    />
                    {"Russian"}
                  </MenuItem>
                </StyledMenu>
              </div>
              <div className="user-box">
                {authMember ? (
                  <img
                    src="/assets/icons/default-user.svg"
                    alt=""
                    onClick={handleLogoutClick}
                  />
                ) : (
                  <NavLink to={"/login"}>
                    <Button variant="contained">{t("Login")}</Button>
                  </NavLink>
                )}

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={Boolean(anchorEl)}
                  onClose={handleCloseLogout}
                  onClick={handleCloseLogout}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {authMember?.memberType === MemberType.ADMIN && (
                    <MenuItem onClick={adminPageHandler}>
                      <ListItemIcon>
                        <AdminPanelSettingsIcon
                          fontSize="small"
                          style={{ color: "blue" }}
                        />
                      </ListItemIcon>

                      {t("Admin")}
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleLogoutRequest}>
                    <ListItemIcon>
                      <Logout fontSize="small" style={{ color: "blue" }} />
                    </ListItemIcon>
                    {t("Logout")}
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </Box>
        </Stack>
      </Container>
    );
  }
};

export default Top;
