import { createSlice } from "@reduxjs/toolkit";
import { AdminPageState, CarPageState } from "../../../lib/types/screen";

const initialState: AdminPageState = {
  cars: [],
  members: [],
};

const carPageSlice = createSlice({
  name: "adminPage",
  initialState,
  reducers: {
    setCars: (state, action) => {
      state.cars = action.payload;
    },
    setMembers: (state, action) => {
      state.members = action.payload;
    },
  },
});

export const { setCars, setMembers } = carPageSlice.actions;

const AdminPageReducer = carPageSlice.reducer;
export default AdminPageReducer;
