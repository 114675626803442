/** SweetAlertHandling **/
import Swal from "sweetalert2";
import { Messages } from "./config";
import i18n from "../i18n"; // Adjust the import path to your i18n configuration

export const sweetErrorHandling = async (err: any) => {
  const error = err.response?.data ?? err;
  const message = error?.message ? error?.message : Messages.error1;
  await Swal.fire({
    icon: "error",
    text: i18n.t(message),
    showConfirmButton: false,
  });
};

export const sweetTopSuccessAlert = async (
  msg: string,
  duration: number = 2000
) => {
  await Swal.fire({
    position: "top-end",
    icon: "success",
    title: i18n.t(msg),
    showConfirmButton: false,
    timer: duration,
  });
};

export const sweetTopSmallSuccessAlert = async (
  msg: string,
  duration: number = 2000
) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: duration,
    timerProgressBar: true,
  });

  Toast.fire({
    icon: "success",
    title: i18n.t(msg),
  }).then();
};

export const sweetFailureProvider = (
  msg: string,
  show_button: boolean = false,
  forward_url: string = ""
) => {
  Swal.fire({
    icon: "error",
    title: i18n.t(msg),
    showConfirmButton: show_button,
    confirmButtonText: "OK",
  }).then(() => {
    if (forward_url !== "") {
      window.location.replace(forward_url);
    }
  });
};

export const sweetConfirmAlert = (msg: string) => {
  return new Promise(async (resolve, reject) => {
    await Swal.fire({
      icon: "question",
      text: i18n.t(msg),
      showClass: {
        popup: "animate__bounceIn",
      },
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#1967d2",
      cancelButtonColor: "#bdbdbd",
    }).then((response) => {
      if (response?.isConfirmed) resolve(true);
      else resolve(false);
    });
  });
};

export const sweetLoginConfirmAlert = (msg: string) => {
  return new Promise(async (resolve, reject) => {
    await Swal.fire({
      text: msg,
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: true,
      color: "#212121",
      confirmButtonColor: "#1967d",
      confirmButtonText: "Login",
    }).then((response) => {
      if (response?.isConfirmed) resolve(true);
      else resolve(false);
    });
  });
};
