import React from "react";
import { Box, Button, Container, Stack } from "@mui/material";
import useDeviceDetect from "../../../lib/detector";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  if (isMobile()) {
    return <div></div>;
  } else {
    return (
      <div className="service-frame">
        <Container>
          <Box className="main-title">{t("Service we provide")}</Box>
          <Stack className="bottom">
            <Box className="info">
              <span>
                {t("Container Shipping to Russia, Central Asia, and Dubai")}
              </span>
              <p>
                {t(
                  "We provide secure container shipping services to transport your vehicle to Russia, Central Asia, and Dubai. Our team ensures that your car is carefully loaded into a container, minimizing the risk of damage during transit. Whether your destination is Moscow, Tashkent, Almaty, or Dubai, we guarantee reliable and timely delivery."
                )}
              </p>
            </Box>
            <Box className="img">
              <img src="/assets/images/car1.png" alt="" />
            </Box>
          </Stack>
        </Container>
      </div>
    );
  }
};

export default Services;
