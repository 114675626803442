import { createSelector } from "reselect";
import { AppRootState } from "../../../lib/types/screen";

const selectAdminPage = (state: AppRootState) => state.adminPage;

export const retrieveCars = createSelector(
  selectAdminPage,
  (adminPage) => adminPage.cars
);

export const retrieveMembers = createSelector(
  selectAdminPage,
  (adminPage) => adminPage.members
);
