import axios from "axios";
import { serverApi } from "../../lib/config";
import {
  LoginInput,
  Member,
  MemberInput,
  MemberUpdateInput,
} from "../../lib/types/member";
import { Car, CarInput, CarInquiry, CarUpdateInput } from "../../lib/types/car";

class CarService {
  private readonly path: string;

  constructor() {
    this.path = serverApi;
  }

  public async getCars(input: CarInquiry): Promise<Car[]> {
    try {
      const inquiry = {
        page: input.page,
        limit: input.limit,
        brand: input.brand ?? "",
        model: input.model ?? "",
        distance: input.distance,
        price: input.price,
        color: input.color ?? "",
      };

      const result = await axios.post(`${this.path}/car/all`, inquiry, {
        withCredentials: true,
      });
      console.log("getCars:", result);

      return result.data;
    } catch (err) {
      console.log("Error, getCars:", err);
      throw err;
    }
  }

  public async getCar(id: string): Promise<Car> {
    try {
      const result = await axios.get(`${this.path}/car/${id}`, {
        withCredentials: true,
      });
      console.log("getCars:", result);

      return result.data;
    } catch (err) {
      console.log("Error, getCars:", err);
      throw err;
    }
  }

  public async getAllCars(input: any): Promise<Car[]> {
    try {
      const url =
        this.path + `/admin/car/all?page=${input.page}&limit=${input.limit}`;
      const result = await axios.get(url, { withCredentials: true });
      console.log("getAllCars:", result);

      return result.data;
    } catch (err) {
      console.log("Error, getAllCars:", err);
      throw err;
    }
  }

  public async updateCar(id: string, data: CarUpdateInput): Promise<Car> {
    try {
      const result = await axios.post(this.path + `/admin/car/${id}`, data, {
        withCredentials: true,
      });
      console.log("updateCar:", result);

      return result.data;
    } catch (err) {
      console.log("Error, updateCar:", err);
      throw err;
    }
  }

  public async createNewCar(files: any, input: any): Promise<Car> {
    try {
      const formData = new FormData();
      formData.append("carName", input.carName);
      formData.append("carPrice", input.carPrice);
      formData.append("carYear", input.carYear);
      formData.append("carColor", input.carColor);
      formData.append("carDistance", input.carDistance);
      formData.append("carFuelType", input.carFuelType);
      formData.append("carSteering", input.carSteering);
      formData.append("carBrand", input.carBrand);
      formData.append("carModel", input.carModel);
      formData.append("carDesc", input.carDesc);
      formData.append("carCondition", input.carCondition);
      formData.append("carLocation", input.carLocation);
      for (let i = 0; i < files.length; i++) {
        formData.append("carImages", files[i]);
      }

      const result = await axios(`${this.path}/admin/car/create`, {
        method: "POST",
        data: formData,
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("createNewCar:", result);

      return result.data;
    } catch (err) {
      console.log("Error, createNewCar:", err);
      throw err;
    }
  }
}

export default CarService;
