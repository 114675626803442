import {
  Box,
  Link,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { createSelector, Dispatch } from "@reduxjs/toolkit";
import { Car, CarUpdateInput } from "../../../lib/types/car";
import { setCars } from "./slice";
import { retrieveCars } from "./selector";
import { useDispatch, useSelector } from "react-redux";
import CarService from "../../services/CarService";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { formatterStr } from "../../../lib/types/common";
import { CarStatus } from "../../../lib/enums/car.enum";
import {
  sweetErrorHandling,
  sweetTopSmallSuccessAlert,
} from "../../../lib/sweetAlert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F7FC",
    color: "#1967D2",
    border: "none",
    padding: "25px 30px",
    [`&.${tableCellClasses.head}:first-child`]: {
      borderRadius: "8px 0 0 8px",
    },
    [`&.${tableCellClasses.head}:last-child`]: {
      borderRadius: "0 8px 8px 0px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  td: {
    padding: "30px 0px",
    border: "none",
    borderBottom: "1px solid #ECEDF2",
    [`&:first-child, &:last-child`]: {
      padding: "30px 30px",
    },
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/** REDUX SLICE & SELECTOR */
const actionDispatch = (dispatch: Dispatch) => ({
  setCars: (data: Car[]) => dispatch(setCars(data)),
});

const carsRetriever = createSelector(retrieveCars, (cars) => ({
  cars,
}));

export default function ProductList() {
  const { setCars } = actionDispatch(useDispatch());
  const { cars } = useSelector(carsRetriever);
  const [filterName, setFilterName] = useState<string>("All");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortingOpen, setSortingOpen] = useState(false);
  const [statusAnchor, setStatusAnchor] = useState<[] | HTMLElement[]>([]);
  const [inquiry, setInquiry] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    const car = new CarService();
    car
      .getAllCars(inquiry)
      .then((data) => {
        setCars(data);
      })
      .catch((err) => console.log(err));
  }, [inquiry]);

  const sortingClickHandler = (e: any) => {
    setAnchorEl(e.currentTarget);
    setSortingOpen(true);
  };

  const sortingCloseHandler = () => {
    setSortingOpen(false);
    setAnchorEl(null);
  };

  const statusClickHandler = (e: any, index: number) => {
    const tempAnchor = statusAnchor.slice();
    tempAnchor[index] = e.currentTarget;
    setStatusAnchor(tempAnchor);
  };
  const statusCloseHandler = () => {
    setStatusAnchor([]);
  };

  const sortingHandler = (e: React.MouseEvent<HTMLLIElement>) => {
    // if (e.currentTarget.id === 'All') delete applicantSearch?.inquiry?.applicationStatus;
    // else applicantSearch.inquiry.applicationStatus = e.currentTarget.id;
    // setApplicantSearch({ ...applicantSearch });
    // setFilterName(e.currentTarget.id);
    // setSortingOpen(false);
    // setAnchorEl(null);
  };

  const paginationHandler = (e: any, value: number) => {
    inquiry.page = value;
    setInquiry({ ...inquiry });
  };

  const updateCarHandler = async (id: string, updateData: CarUpdateInput) => {
    try {
      const car = new CarService();

      await car.updateCar(id, updateData);

      setInquiry({ ...inquiry });

      await sweetTopSmallSuccessAlert("successfully updated!", 700);
    } catch (err) {
      console.log(err);
      sweetErrorHandling(err).then();
    }
  };

  return (
    <Stack className="wrap">
      <Box className="filter-box">
        <span className="title">Mashinalar</span>
        <div className="filter"></div>
      </Box>
      <Box className="table-wrap">
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Nom</StyledTableCell>
                <StyledTableCell align="center">Narx</StyledTableCell>
                <StyledTableCell align="center">Brand</StyledTableCell>
                <StyledTableCell align="center">Model</StyledTableCell>
                <StyledTableCell align="center">Yil</StyledTableCell>
                <StyledTableCell align="center">Rang</StyledTableCell>
                <StyledTableCell align="center">Ko'rilgan</StyledTableCell>
                <StyledTableCell align="center">Yoqtirilgan</StyledTableCell>
                <StyledTableCell align="center">Xolat</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cars.length !== 0 ? (
                cars.map((car: Car, index: number) => {
                  return (
                    <StyledTableRow key={"index"}>
                      <StyledTableCell>{car.carName}</StyledTableCell>
                      <StyledTableCell align="center">
                        {formatterStr(car.carPrice)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {car.carBrand}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {car.carModel}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {car.carYear}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {car.carColor}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {car.carViews}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {car.carLikes}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <div
                          className="data-status"
                          onClick={(e: any) => statusClickHandler(e, index)}
                        >
                          {car.carStatus}
                        </div>
                        <Menu
                          sx={{ mt: "20px" }}
                          anchorEl={statusAnchor[index]}
                          open={Boolean(statusAnchor[index])}
                          onClose={statusCloseHandler}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          {Object.values(CarStatus)
                            .filter((ele) => ele !== car.carStatus)
                            .map((status: CarStatus) => {
                              return (
                                <MenuItem
                                  onClick={() => {
                                    statusCloseHandler();
                                    updateCarHandler(car._id, {
                                      carStatus: status,
                                    });
                                  }}
                                  key={status}
                                >
                                  {status}
                                </MenuItem>
                              );
                            })}
                        </Menu>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div className="no-data">
                      <InfoOutlinedIcon />
                      <span>No data found!</span>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack className="pagination-box">
        <Pagination
          color="primary"
          count={cars.length !== 0 ? inquiry.page + 1 : inquiry.page}
          page={inquiry.page}
          onChange={paginationHandler}
        />
      </Stack>
    </Stack>
  );
}
