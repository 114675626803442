import { Box, Button, Container, Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import useDeviceDetect from "../../../lib/detector";
import { useHistory } from "react-router-dom";
import { CarBrand, CarColor } from "../../../lib/enums/car.enum";
import {
  carDistanceRanges,
  carModels,
  carPriceRanges,
  Messages,
  serverApi,
} from "../../../lib/config";
import { createSelector, Dispatch } from "@reduxjs/toolkit";
import { Car, CarInquiry } from "../../../lib/types/car";
import { useDispatch, useSelector } from "react-redux";
import { setCars } from "./slice";
import CarService from "../../services/CarService";
import { retrieveCars } from "./selector";
import { formatterStr } from "../../../lib/types/common";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { LikeGroupType } from "../../../lib/enums/like.enum";
import { sweetErrorHandling } from "../../../lib/sweetAlert";
import MemberService from "../../services/MemberService";
import { useGlobals } from "../../hooks/useGlobals";
import { useTranslation } from "react-i18next";

/** REDUX SLICE & SELECTOR */
const actionDispatch = (dispatch: Dispatch) => ({
  setCars: (data: Car[]) => dispatch(setCars(data)),
});

const popularDishesRetriever = createSelector(retrieveCars, (cars) => ({
  cars,
}));

const Products = () => {
  /** INITIALIZATIONS **/
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  const { setCars } = actionDispatch(useDispatch());
  const { cars } = useSelector(popularDishesRetriever);
  const { authMember } = useGlobals();
  const productsList = Array.from(Array(8).keys());
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();
  const [inquiry, setInquiry] = useState<CarInquiry>({
    page: 1,
    limit: 6,
  });

  useEffect(() => {
    const car = new CarService();
    car
      .getCars(inquiry)
      .then((data) => {
        setCars(data);
      })
      .catch((err) => console.log(err));
  }, [inquiry]);

  /** HANDLERS **/
  const handleClick = () => {
    setShowAll(true);
  };

  const pushChosenDish = (id: string) => {
    console.log(pushChosenDish);
    history.push(`/products/${id}`);
  };

  const changeBrand = (e: any) => {
    const value = e.target.value;
    setInquiry({ ...inquiry, brand: value });
  };

  const changeModel = (e: any) => {
    const value = e.target.value;
    setInquiry({ ...inquiry, model: value });
  };

  const changeColor = (e: any) => {
    const value = e.target.value;
    setInquiry({ ...inquiry, color: value });
  };

  const changePrice = (e: any) => {
    let cleanedRange = e.target.value
      .split("-")
      .map((part: any) => part.replace(/,/g, ""));

    setInquiry({
      ...inquiry,
      price: {
        start: cleanedRange[0] ? parseInt(cleanedRange[0]) : 0,
        end: cleanedRange[1] ? parseInt(cleanedRange[1]) : 999999,
      },
    });
  };

  const changeDistance = (e: any) => {
    let cleanedRange = e.target.value
      .split("-")
      .map((part: any) => part.replace(/,/g, ""));

    setInquiry({
      ...inquiry,
      distance: {
        start: cleanedRange[0] ? parseInt(cleanedRange[0]) : 0,
        end: cleanedRange[1] ? parseInt(cleanedRange[1]) : 999999,
      },
    });
  };

  const likeProcessHandler = async (id: string) => {
    try {
      if (!authMember) throw new Error(Messages.error2);

      const member = new MemberService();
      const result = await member.likeProcess({
        likeRefId: id,
        groupType: LikeGroupType.CAR,
      });
      setInquiry({ ...inquiry });
    } catch (err: any) {
      console.log("Error: likeProcessHandler:", err);
      sweetErrorHandling(err);
    }
  };

  if (isMobile()) {
    return (
      <div className="products-frame">
        <div className="m-container">
          <Stack className="filter-box">
            <strong>{t("Find your car")}</strong>
            <Stack className="filter">
              <Box>
                <span>{t("Brand")}</span>
                <select name="" id="" onChange={changeBrand}>
                  <option value="">{t("select")}</option>
                  {Object.values(CarBrand).map((brand: CarBrand) => (
                    <option value={brand}>{brand}</option>
                  ))}
                </select>
              </Box>
              <Box>
                <span>{t("Model")}</span>
                <select name="" id="" onChange={changeModel}>
                  <option value="">{t("select")}</option>
                  {inquiry?.brand &&
                    carModels[inquiry?.brand].map((brand: string) => (
                      <option value={brand}>{brand}</option>
                    ))}
                </select>
              </Box>
              <Box>
                <span>{t("Distance")}</span>
                <select name="" id="" onChange={changeDistance}>
                  <option value="">{t("select")}</option>
                  {carDistanceRanges.map((distance: string) => (
                    <option value={distance}>{distance}</option>
                  ))}
                </select>
              </Box>
              <Box>
                <span>{t("Price range")}</span>
                <select name="" id="" onChange={changePrice}>
                  <option value="">{t("select")}</option>
                  {carPriceRanges.map((price: string) => (
                    <option value={price}>{price}</option>
                  ))}
                </select>
              </Box>
              <Box>
                <span>{t("Color")}</span>
                <select name="" id="" onChange={changeColor}>
                  <option value="">{t("select")}</option>
                  {Object.values(CarColor).map((color: string) => (
                    <option value={color}>{color}</option>
                  ))}
                </select>
              </Box>
            </Stack>
          </Stack>
          <Stack className="product-wrap">
            {cars.map((car: Car, index: number) => (
              <Box
                key={index}
                sx={{
                  marginTop: "16px",
                  display: showAll || index < 3 ? "flex" : "none",
                  gap: "16px",
                  borderRadius: "24px",
                  border: "1px solid #D7E1ED",
                  background: "#FFF",
                  padding: "11px 14px 7px 13px",
                  position: "relative",
                  flexDirection: "column",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: "24px",
                    background:
                      showAll || (index >= 2 && index < 3)
                        ? "linear-gradient(180deg, rgba(248, 251, 255, 0.82) 0%, rgba(248, 251, 255, 0.99) 65.85%);"
                        : "none",
                    pointerEvents: "none",
                    zIndex: 10,
                  },
                }}
              >
                <Box>
                  <img
                    src={`${serverApi}/${car.carImages[0]}`}
                    alt=""
                    className="car-img"
                  />
                </Box>
                <Box position={"relative"}>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      borderRadius: "var(--numbers-radius-base, 8px)",
                      border: "1px solid #D7E1ED",
                      background: "rgba(215, 225, 237, 0.20)",
                      width: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => likeProcessHandler(car._id)}
                  >
                    {car.meLiked && car?.meLiked[0]?.myFavorite ? (
                      <Favorite style={{ color: "red" }} />
                    ) : (
                      <FavoriteBorder />
                    )}
                  </Box>
                  <p className="car-price">${formatterStr(car.carPrice)}</p>
                  <p
                    className="car-title"
                    onClick={() => pushChosenDish(car._id)}
                  >
                    {car.carName}
                  </p>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      mt: "14px",
                    }}
                  >
                    <div className="details">
                      <p className="car-details">{t("Year")}</p>
                      <p className="car-details">{car.carYear}</p>
                    </div>
                    <div className="details car-details-container">
                      <p className="car-details">{t("Color")}</p>
                      <p className="car-details">{car.carColor}</p>
                    </div>
                    <div className="details">
                      <p className="car-details">{t("Distance")}</p>
                      <p className="car-details">{car.carDistance}km</p>
                    </div>
                  </Box>
                  <p className="car-location">
                    <img src="/assets/icons/world.svg" alt="" />
                    <span>{car.carLocation}</span>
                  </p>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      mt: "14px",
                      visibility:
                        !showAll && (index === 4 || index === 5)
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onClick={() => pushChosenDish(car._id)}
                    >
                      {t("View more")}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onClick={() => pushChosenDish(car._id)}
                    >
                      {t("Get in touch")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
          {cars?.length > 3 && !showAll && (
            <Box
              mt="20px"
              textAlign={"center"}
              sx={{
                position: "absolute",
                bottom: "5%",
                left: "32%",
                display: "flex",
              }}
            >
              <Button
                variant="outlined"
                endIcon={<ArrowForwardIosIcon />}
                sx={{
                  padding: "14px 12px 14px 24px",
                  borderRadius: "12px",
                  textTransform: "capitalize",
                  color: "var(--Main-dark-color, #1E1E1E)",
                  fontFamily: "Rubik",
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  zIndex: 12,
                  background: "rgba(0, 86, 187, 0.05)",
                }}
                onClick={() => history.push("/products")}
              >
                {t("View more")}
                <img src="/assets/images/arrowRight.svg" alt="" />
              </Button>
            </Box>
          )}
          {cars.length === 0 && (
            <Stack
              alignItems={"center"}
              height={"200px"}
              justifyContent={"center"}
              style={{
                fontSize: "26px",
                fontWeight: 500,
                fontFamily: "Rubik",
              }}
            >
              {t("Please select other options")}
            </Stack>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="products-container">
        <Container
          maxWidth="lg"
          sx={{
            position: "relative",
          }}
        >
          <Stack className="filter-box">
            <Box className="filter">
              <span>{t("Brand")}</span>
              <select name="" id="" onChange={changeBrand}>
                <option value="">{t("Select")}</option>
                {Object.values(CarBrand).map((brand: CarBrand) => (
                  <option value={brand}>{brand}</option>
                ))}
              </select>
            </Box>
            <Box className="filter">
              <span>{t("Model")}</span>
              <select name="" id="" onChange={changeModel}>
                <option value="">{t("Select")}</option>
                {inquiry?.brand &&
                  carModels[inquiry?.brand].map((brand: string) => (
                    <option value={brand}>{brand}</option>
                  ))}
              </select>
            </Box>
            <Box className="filter">
              <span>{t("Distance")}</span>
              <select name="" id="" onChange={changeDistance}>
                <option value="">{t("Select")}</option>
                {carDistanceRanges.map((distance: string) => (
                  <option value={distance}>{distance}</option>
                ))}
              </select>
            </Box>
            <Box className="filter">
              <span>{t("Price range")}</span>
              <select name="" id="" onChange={changePrice}>
                <option value="">{t("Select")}</option>
                {carPriceRanges.map((price: string) => (
                  <option value={price}>{price}</option>
                ))}
              </select>
            </Box>
            <Box className="filter">
              <span>{t("Color")}</span>
              <select name="" id="" onChange={changeColor}>
                <option value="">{t("Select")}</option>
                {Object.values(CarColor).map((color: string) => (
                  <option value={color}>{color}</option>
                ))}
              </select>
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
              mt: "20px",
            }}
          >
            {cars?.length !== 0 &&
              cars.map((car: Car, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "628px",
                    display: showAll || index < 6 ? "flex" : "none",
                    gap: "16px",
                    borderRadius: "24px",

                    border: "1px solid #D7E1ED",
                    background: "#FFF",
                    padding: "11px 14px 7px 13px",
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: "24px",
                      background:
                        showAll || (index >= 4 && index < 6)
                          ? "linear-gradient(180deg, rgba(248, 251, 255, 0.82) 0%, rgba(248, 251, 255, 0.99) 65.85%);"
                          : "none",
                      pointerEvents: "none",
                      zIndex: 10,
                      cursor: "pointer",
                    },
                  }}
                >
                  <Box>
                    <img
                      src={`${serverApi}/${car.carImages[0]}`}
                      alt=""
                      className="car-box-img"
                      onClick={() => pushChosenDish(car._id)}
                    />
                  </Box>
                  <Box>
                    <p className="car-price">${formatterStr(car.carPrice)}</p>
                    <p
                      className="car-title"
                      onClick={() => pushChosenDish(car._id)}
                    >
                      {car.carName}
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        mt: "14px",
                      }}
                    >
                      <div>
                        <p className="car-details">{t("Year")}</p>
                        <p className="car-details">{car.carYear}</p>
                      </div>
                      <div className="car-details-container">
                        <p className="car-details">{t("Color")}</p>
                        <p className="car-details">{car.carColor}</p>
                      </div>
                      <div>
                        <p className="car-details">{t("Distance")}</p>
                        <p className="car-details">{car.carDistance}km</p>
                      </div>
                    </Box>
                    <p className="car-location">
                      <img src="/assets/icons/world.svg" alt="" />
                      <span>{car.carLocation}</span>
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        mt: "14px",
                        visibility:
                          !showAll && (index === 4 || index === 5)
                            ? "hidden"
                            : "visible",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => pushChosenDish(car._id)}
                      >
                        {t("View more")}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => pushChosenDish(car._id)}
                      >
                        {t("Get in touch")}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      right: "12px",
                      borderRadius: "var(--numbers-radius-base, 8px)",
                      border: "1px solid #D7E1ED",
                      background: "rgba(215, 225, 237, 0.20)",
                      width: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => likeProcessHandler(car._id)}
                  >
                    {car.meLiked && car?.meLiked[0]?.myFavorite ? (
                      <Favorite style={{ color: "red" }} />
                    ) : (
                      <FavoriteBorder />
                    )}
                  </Box>
                </Box>
              ))}
          </Stack>
          {cars?.length > 4 && !showAll && (
            <Box
              mt="20px"
              textAlign={"center"}
              sx={{
                position: "absolute",
                bottom: "15%",
                left: "46%",
                display: "flex",
              }}
            >
              <Button
                variant="outlined"
                endIcon={<ArrowForwardIosIcon />}
                sx={{
                  textTransform: "capitalize",
                  color: "var(--Main-dark-color, #1E1E1E)",
                  fontFamily: "Rubik",
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  zIndex: 12,
                  background: "rgba(0, 86, 187, 0.05)",
                }}
                onClick={() => history.push("/products")}
              >
                {t("View more")}
                <img src="/assets/images/arrowRight.svg" alt="" />
              </Button>
            </Box>
          )}
          {cars.length === 0 && (
            <Stack
              alignItems={"center"}
              height={"200px"}
              justifyContent={"center"}
              style={{
                fontSize: "26px",
                fontWeight: 500,
                fontFamily: "Rubik",
              }}
            >
              {t("Please select other options")}
            </Stack>
          )}
        </Container>
      </div>
    );
  }
};

export default Products;
