import { Box, Container, Stack } from "@mui/material";
import { NavLink } from "react-router-dom";
import useDeviceDetect from "../../../lib/detector";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  if (isMobile()) {
    return (
      <div className="m-footer-frame">
        <div className="m-container">
          <span>
            {t("Ready to Explore Our Selection? Get in Touch Today")}!
          </span>
          <Box display={"flex"} justifyContent={"center"}>
            <a
              href="https://www.instagram.com/mx_korea?igsh=cTNkYzhydGhqcG9x"
              target="_blank"
            >
              <img src="/assets/icons/instagram.svg" alt="" />
            </a>
            <a href="https://t.me/hurshid_13" target="_blank">
              <img src="/assets/icons/telegram.svg" alt="" />
            </a>
            <a
              href="https://www.instagram.com/mx_korea?igsh=cTNkYzhydGhqcG9x"
              target="_blank"
            >
              <img src="/assets/icons/linkedin.svg" alt="" />
            </a>
            <a
              href="https://www.instagram.com/mx_korea?igsh=cTNkYzhydGhqcG9x"
              target="_blank"
            >
              <img src="/assets/icons/youtube.svg" alt="" />
            </a>
          </Box>
        </div>
      </div>
    );
  } else {
    return (
      <div className="footer-frame">
        <Container>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <span>
              {t("Ready to Explore Our Selection? Get in Touch Today")}!
            </span>
            <Box>
              <a
                href="https://www.instagram.com/mx_korea?igsh=cTNkYzhydGhqcG9x"
                target="_blank"
              >
                <img src="/assets/icons/instagram.svg" alt="" />
              </a>
              <a href="https://t.me/hurshid_13" target="_blank">
                <img src="/assets/icons/telegram.svg" alt="" />
              </a>
              <a
                href="https://www.instagram.com/mx_korea?igsh=cTNkYzhydGhqcG9x"
                target="_blank"
              >
                <img src="/assets/icons/linkedin.svg" alt="" />
              </a>
              <a
                href="https://www.instagram.com/mx_korea?igsh=cTNkYzhydGhqcG9x"
                target="_blank"
              >
                <img src="/assets/icons/youtube.svg" alt="" />
              </a>
            </Box>
          </Stack>
        </Container>
      </div>
    );
  }
};

export default Footer;
