import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import Top from "./Top";
import useDeviceDetect from "../../../lib/detector";
import { useTranslation } from "react-i18next";
const images = [
  "/assets/images/home_hero.png",
  "/assets/images/home_hero_second.png",
];

const NavbarHome = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  if (isMobile()) {
    return (
      <div className="mobile-home-navbar">
        <div className="navbar-frame">
          <div className="top-menu">
            <Top other={false} />
          </div>
          <div className="header-content m-container">
            <p className="mobile-hidden-text">
              {t("Discover Quality Korean Cars, Delivered to Your Doorstep")}
            </p>
            <Box
              className="search-box"
              sx={{
                textAlign: "center",
                mt: "30px",
              }}
            >
              <input
                type="text"
                className="search-input"
                placeholder={t("Explore Our Featured Selection")}
                color="#fff"
              />
              <img
                className="search-icon"
                src="/assets/icons/search.svg"
                alt=""
              />
            </Box>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pc-navbar">
        <div
          className={`navbar-container`}
          style={{
            backgroundImage: `url(${images[currentImageIndex]})`,
          }}
        >
          <div className="navbar-config">
            <Top other={false} />
            <Container>
              <Box
                className="main-homepage-text"
                sx={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("Discover Quality Korean Cars, Delivered to Your Doorstep")}
              </Box>
              <Box
                className="search-box"
                sx={{
                  textAlign: "center",
                  mt: "30px",
                }}
              >
                <input
                  type="text"
                  className="search-input"
                  placeholder={t("Explore Our Featured Selection")}
                  color="#fff"
                />
                <img
                  className="search-icon"
                  src="/assets/icons/search.svg"
                  alt=""
                />
              </Box>
            </Container>
          </div>
        </div>
      </div>
    );
  }
};

export default NavbarHome;
