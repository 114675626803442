import numeral from "numeral";

export interface T {
  [key: string]: any;
}

export const formatterStr = (value: number | undefined): string => {
  return numeral(value).format("0,0") != "0"
    ? numeral(value).format("0,0")
    : "";
};

export interface TelegramMessage {
  name: string;
  phone: string;
  carName?: string;
  carBrand?: string;
  carModel?: string;
  carYear?: string;
  carColor?: string;
  carImage?: string;
  text: string;
}
