import { createSelector } from "reselect";
import { AppRootState } from "../../../lib/types/screen";

const selectCarPage = (state: AppRootState) => state.carPage;

export const retrieveCars = createSelector(
  selectCarPage,
  (carPage) => carPage.cars
);

export const retrieveChosenCar = createSelector(
  selectCarPage,
  (carPage) => carPage.chosenCar
);

export const retrieveSimilarCars = createSelector(
  selectCarPage,
  (carPage) => carPage.similarCars
);
