import { createSlice } from "@reduxjs/toolkit";
import { CarPageState } from "../../../lib/types/screen";

const initialState: CarPageState = {
  cars: [],
  chosenCar: null,
  similarCars: [],
};

const carPageSlice = createSlice({
  name: "carPage",
  initialState,
  reducers: {
    setCars: (state, action) => {
      state.cars = action.payload;
    },
    setChosenCar: (state, action) => {
      state.chosenCar = action.payload;
    },
    setSimilarCars: (state, action) => {
      state.similarCars = action.payload;
    },
  },
});

export const { setCars, setChosenCar, setSimilarCars } = carPageSlice.actions;

const CarPageReducer = carPageSlice.reducer;
export default CarPageReducer;
