import React from "react";
import { Box, Button, Container, Stack } from "@mui/material";
import useDeviceDetect from "../../../lib/detector";
import { useTranslation } from "react-i18next";

const Advertisements = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  if (isMobile()) {
    return (
      <div className="adv-frame">
        <div className="m-container">
          <Box className="main-title">
            {t("Experience the Difference")} <br /> {t("with")}
            <img src="/assets/images/logo_black.svg" alt="" />
          </Box>
          <Stack className="adv-main">
            <img src="/assets/icons/adv-1.svg" alt="" className="bg-img one" />
            <img src="/assets/icons/adv-2.svg" alt="" className="bg-img two" />
            <img
              src="/assets/icons/adv-3.svg"
              alt=""
              className="bg-img three"
            />
            <Stack className="adv-card">
              <Box className="main">
                {t(
                  `Reliable sourcing: Direct access to a diverse range of Korean vehicles, including popular brands and models`
                )}
                .
              </Box>
            </Stack>
            <Stack className="adv-card">
              <Box className="main">
                {t(
                  `Transparent process: Clear pricing, comprehensive vehicle information, and assistance at every stage of the buying process`
                )}
                .
              </Box>
            </Stack>
            <Stack className="adv-card ">
              <Box className="main">
                {t(
                  `Worldwide delivery: From the bustling streets of Dubai to the scenic landscapes of Uzbekistan and beyond, we ensure your dream car reaches you safely and efficiently`
                )}
                .
              </Box>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  } else {
    return (
      <div className="adv-frame">
        <Container>
          <Box className="main-title">
            {t("Experience the Difference with")}
            <img src="/assets/images/logo_black.svg" alt="" />
          </Box>
          <Stack className="adv-main">
            <Stack className="adv-card">
              <Box className="bg-img">
                <img src="/assets/icons/adv-1.svg" alt="" />
              </Box>
              <Box className="main" sx={{ width: "554px" }}>
                {t(
                  `Reliable sourcing: Direct access to a diverse range of Korean vehicles, including popular brands and models`
                )}
                .
              </Box>
            </Stack>
            <Stack className="adv-card right">
              <Box className="bg-img right">
                <img src="/assets/icons/adv-2.svg" alt="" />
              </Box>
              <Box className="main right" sx={{ width: "604px" }}>
                {t(
                  `Transparent process: Clear pricing, comprehensive vehicle information, and assistance at every stage of the buying process`
                )}
                .
              </Box>
            </Stack>
            <Stack className="adv-card center">
              <Box className="bg-img center">
                <img src="/assets/icons/adv-3.svg" alt="" className="bottom" />
              </Box>
              <Box className="main" sx={{ width: "760px" }}>
                {t(
                  `Worldwide delivery: From the bustling streets of Dubai to the scenic landscapes of Uzbekistan and beyond, we ensure your dream car reaches you safely and efficiently`
                )}
                .
              </Box>
            </Stack>
          </Stack>
        </Container>
      </div>
    );
  }
};

export default Advertisements;
