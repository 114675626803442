import React, { useState } from "react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import HomePage from "./screens/homePage";
import "../scss/index.scss";
import Footer from "./components/footer";
import ProductsPage from "./screens/productsPage";
import NavbarHome from "./components/header/HomeNavbar";
import OtherNavbar from "./components/header/OtherNavbar";
import AboutPage from "./screens/aboutPage";
import ServicePage from "./screens/servicePage";
import ContactPage from "./screens/contactPage";
import AdminPage from "./screens/adminPage";
import AdminLogin from "./screens/adminPage/AdminLogin";
import LoginPage from "./screens/loginPage";

function App() {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/" ? <NavbarHome /> : <OtherNavbar />}
      <Switch>
        <Route path="/products">
          <ProductsPage />
        </Route>
        <Route path="/service">
          <ServicePage />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/contact">
          <ContactPage />
        </Route>
        <Route path="/admin">
          <AdminPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default App;
